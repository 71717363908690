import './bootstrap';

(function ($) {
    "use strict";

    // JS EFFET NAVBAR AU TOP (version STUCK)
    (new IntersectionObserver(function (e, o) {
        if (e[0].intersectionRatio > 0) {
            document.documentElement.removeAttribute('class');
        } else {
            document.documentElement.setAttribute('class', 'stuck');
        };
    })).observe(document.querySelector('.trigger'));

    // LIEN PREMIER NIVEAU AVEC DROPDOWN CLICKABLE
    $(document).ready(function () {
        function checkWidth() {
            var windowSize = $(window).width();
            if (windowSize > 768) {
                $('.dropdown-toggle').removeAttr('data-toggle');
            }
        }
        checkWidth();
        $(window).resize(checkWidth);
    });

    // BS4 TOOLTIP JS
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    // SMOOTH SCROLLING USING JQUERY EASING
    $('a.coti-scroll-spy[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - 35)
                }, 1000, "easeInOutExpo");
                return false;
            }
        }
    });

    // NAV
    // Closes responsive menu when a scroll trigger link is clicked
    $('.coti-scroll-spy').click(function () {
        $('.navbar-collapse').collapse('hide');
    });

    // Activate scrollspy to add active class to navbar items on scroll
    $('body').scrollspy({
        target: '#cotiNav',
        offset: 70
    });

    // Collapse Navbar
    var navbarCollapse = function () {
        if ($("#cotiNav").offset().top > 50) {
            $("#cotiNav").addClass("navbar-shrink");
        } else {
            $("#cotiNav").removeClass("navbar-shrink");
        }
    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);

    // GO TOP
    var btn = $('#goTop');
    $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
            btn.show(10).animate({
                right: '0'
            }, 0);
        } else {
            btn.animate({
                right: '-70px'
            }, 0);
        }
    });

    // EXPERIENCE MOBILE MENU
/* 
    $('.toggle').on('click', function () {
        $('.menu').toggleClass('active');
    });
 */    

})(jQuery);
